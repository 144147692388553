import React, {useState} from 'react';  
import './preDeliveryInspection.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import pdiInspector from './mascot.png';
import yellowCar from './yellow_car.png';
// import blackCar from './black_car.webp';
import dividerLines from './homePageLines.png';

import mechanicalIssues from './MechanicalIssues.png';
import engineHealth from './engineHealth.png';
import batteryAndTyreHealth from './batteryAndTyreHealth.png';
import paintQualityCheck from './paintQualityCheck.png';
import carElectronics from './carElectronics.png';
import essentialFluids from './essentialFluids.png';
import softwareIssues from './softwareIssues.png';
import interiorInspection from './interiorInspection.png';

import mascotToRight from './mascotLookingToRight.png';
import noTieUps from './NoTieUps.png';
import qualityChecks from './qualityChecks.png';
import trainedProfessionals from './trainedProfessionals.png';

import reviewImage1 from './reviewImage1.png';
import reviewImage2 from './reviewImage2.png';
import reviewImage3 from './reviewImage3.png';

import doubleLineRoad from './double_line_road.svg';

import arrowImage from './arrows.png';

import callButton from './callButtonNoBg.png';
const PreDeliveryInspection = () => {

  const [currentReview, setCurrentReview] = useState(0);  
  const reviews = [  
   {  
    image: reviewImage1,
    text: "reviewImage1"  
   },
   {  
    image: reviewImage2, 
    text: "reviewImage2"
   },  
   {  
    image: reviewImage3,
    text: "reviewImage3" 
   },  
  ];  
  
  const handleNextReview = () => {  
   setCurrentReview((currentReview + 1) % reviews.length);  
  };  
  
  const handlePrevReview = () => {  
   setCurrentReview((currentReview - 1 + reviews.length) % reviews.length);  
  };  

  return (  
   <div className="pdi-container">  
    <div className="pdi-hero-section"> {/* Add a new container div for the hero section */}  
      <h1 className="pdi-title">  
                 <span className="pdi-highlight">P</span>re <span className="pdi-highlight">D</span>elivery <span className="pdi-highlight">I</span>nspection  
                 <br />  
                 made easy
                </h1>  
      <div className="pdi-content">  
       <div className="pdi-left">  
       <p className="pdi-description">Hassle-free PDI Services<br/>Buying New CAR, We are here to Help!</p> 
                            <br /> 
        <button className="pdi-book-button">
                              <span class="text">Book My PDI for just <span class="price">₹2499/-</span> </span>
                              <span class="icon"><img src={arrowImage} alt="Arrow icon" /></span>
                            </button>
                            <br />
       </div>  
       <div className="pdi-right">  
        <img src={yellowCar} alt="Small yellow car" className="pdi-yellow-car-image" />  
        <img src={pdiInspector} alt="Inspector" className="pdi-inspector-image" />  
       </div> 
                      <div className="pdi-divider">  
           <img src={dividerLines} alt="Parallel lines" className="pdi-parallel-lines-image" />  
          </div> 
      </div>  
  
    </div>  
    <div className="pdi-what-we-offer-container"> {/* Start a new container div for the what we offer section */}  
      <div className="pdi-what-we-offer">  
       <h2>What we offer?</h2>
                      <p className="pdi-what-we-offer-description">  
                        Before buying a car, getting a PDI is your right and we give you a
                        <br />
                        comprehensive inspection for your new car's health and reliability.  
                       </p>
                      <ul>  
                        <li>  
                          <img src={mechanicalIssues} alt="Mechanical issues" />  
                          <span>Identifying mechanical issues</span>  
                        </li>  
                        <li>  
                          <img src={engineHealth} alt="Engine health" />  
                          <span>Engine health diagnosis</span>  
                        </li>  
                        <li>  
                          <img src={batteryAndTyreHealth} alt="Battery and tyre" />  
                          <span>Battery and tyre health</span>  
                        </li>  
                        <li>  
                          <img src={paintQualityCheck} alt="Paint quality" />  
                          <span>Paint quality check</span>  
                        </li>  
                        <li>  
                          <img src={carElectronics} alt="Electricals" />  
                          <span>Inspection of car electricals</span>  
                        </li>  
                        <li>  
                          <img src={essentialFluids} alt="Essential fluids" />  
                          <span>Inspection of essential fluids</span>  
                        </li>  
                        <li>  
                          <img src={softwareIssues} alt="Software issues" />  
                          <span>Identifying software issues</span>  
                        </li>  
                        <li>  
                          <img src={interiorInspection} alt="Car interior" />  
                          <span>Car interior inspection</span>  
                        </li>  
                       </ul>
      </div>  
    </div>
              <div className="pdi-we-have-your-back-container"> {/* Start a new container div for the we have your back */}  
      <div className="pdi-we-have-your-back"> 
       <img src={mascotToRight} alt="Inspector Penguin" class="pdi-penguin-image" />
                      <div class="pdi-heading-text">
                          <h1 class="pdi-main-heading">We have your back...</h1>
                          <p class="pdi-subtext">Drive home your car with trust and quality assurance after our rigorous 360-degree inspection.</p>
                      </div>
                      <div class="pdi-features">
                        <div class="feature">
                            <img src={qualityChecks} alt="Mechanical Inspection" class="feature-image" />
                            <h3 class="feature-title">Identifying Mechanical Issues</h3>
                            <p class="feature-description">We ensure that every car meets our standards of quality and safety.</p>
                        </div>
                        <div class="feature">
                            <img src={noTieUps} alt="No Tie-Ups with Dealers" class="feature-image" />
                            <h3 class="feature-title">No Tie-Ups with CAR Dealers</h3>
                            <p class="feature-description">We are independent and unbiased, ensuring that you get the best deal possible.</p>
                        </div>
                        <div class="feature">
                            <img src={trainedProfessionals} alt="Trained Professionals" class="feature-image" />
                            <h3 class="feature-title">Trained Professional Experts</h3>
                            <p class="feature-description">Our team of experts is trained to provide you with the best possible service.</p>
                        </div>
                    </div>
      </div>  
    </div>
              <div className = "pdi-assistance-container">
                <div className="assistance-section">
                  {/* Heading */}
                  <h1>Need a hand understanding <br/> your PDI needs?</h1>

                  <div className="decorative-lines-container">
                    <img src={doubleLineRoad} alt="Decorative lines" className="decorative-lines" />
                    
                  </div>

                  <a href="tel:9129781297" className="phone-button">
                      91297 81297
                      <span className="phone-icon"><img src={callButton} alt="PDI Call button" class="pdi-call-button-icon" /></span>
                    </a>

                  <p>Our in-house experts know all the pre delivery <br/> inspection needs for your car.</p>

                  {/* Bottom Links */}
                  <div className="bottom-links">
                    <a href="#live-chat">
                      <span className="icon">💬</span> Live chat
                    </a>
                    <a href="#email-us">
                      <span className="icon">📧</span> Email us
                    </a>
                  </div>
                </div>
              </div>
              <div className="pdi-reviews-container">  
                <h2>Our Reviews</h2>  
                <div className="pdi-reviews-slider">  
                 <button className="pdi-prev-button" onClick={handlePrevReview}>  
                  <i className="fas fa-chevron-left"></i>  
                 </button>  
                 <div className="pdi-review">  
                  <img src={reviews[currentReview].image} alt={reviews[currentReview].text} /> 
                  <div className="pdi-review-dots">  
                    {reviews.map((review, index) => (  
                     <span  
                      key={index}  
                      className={`pdi-review-dot ${index === currentReview ? 'active' : ''}`}  
                      onClick={() => setCurrentReview(index)}  
                     />  
                    ))}  
                  </div> 
                 </div>  
                 <button className="pdi-next-button" onClick={handleNextReview}>  
                  <i className="fas fa-chevron-right"></i>  
                 </button>  
                </div>  
              </div>
              <div className="pdi-makes-and-models-container">  
                <h2>We cover all Makes and Models</h2>  
                <ul>  
                 <li>Toyota</li>  
                 <li>Honda</li>  
                 <li>Hyundai</li>  
                 <li>Maruti Suzuki</li>  
                 <li>Tata Motors</li>  
                 <li>Mahindra</li>  
                 <li>Renault</li>  
                 <li>Nissan</li>
                 <li>Volkswagen</li>  
                 <li>BMW</li>  
                 <li>Mercedes-Benz</li>  
                 <li>Audi</li>  
                </ul>  
              </div>            
      <div className="social-icons">
                   <div className="social-icon">
                      <i className="fab fa-facebook-f"></i>
                    </div>
                    <div className="social-icon">
                      <i className="fab fa-twitter"></i>
                    </div>
                    <div className="social-icon">
                      <i className="fab fa-instagram"></i>
                    </div>
                </div> 
   </div>  
  );  
};  
  
export default PreDeliveryInspection;

