import React from 'react';  
import './footer.css';  
  
const Footer = () => {  
  return (  
   <footer className="pdi-footer">  
    <div className="pdi-footer-container">  
      <div className="pdi-footer-column">  
       <h3>Meet MotorPDI</h3>  
       <ul>  
        <li><a href="#">About Us</a></li>  
        <li><a href="#">Services and prices</a></li>  
        <li><a href="#">How it works</a></li>  
       </ul>  
      </div>  
      <div className="pdi-footer-column">  
       <h3>Terms</h3>  
       <ul>  
        <li><a href="#">Terms and Conditions</a></li>  
        <li><a href="#">Privacy Policy</a></li>  
       </ul>  
      </div>  
      <div className="pdi-footer-column">  
       <h3>Find us at:</h3>  
       <p>
                        A-901 Greater Noida
                        <br />
                        <br />
                        91297-81297
                        <br />
                        customercare@motorpdi.com
                      </p> 
      </div>  
     </div>  
    <div className="pdi-footer-bottom">  
      <p>&copy; 2024 MotorPDI. All rights reserved.</p>  
    </div>  
   </footer>  
  );  
};  
  
export default Footer;
